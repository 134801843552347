import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../../components/seo'
import Layout from '../../components/layout'
import { Grid, Card, Box, CardMedia, Typography } from '@material-ui/core'
import { CardContent, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { FaCalendarDay } from '@react-icons/all-files/fa/FaCalendarDay'
import {FaReadme} from '@react-icons/all-files/fa/FaReadme'
import { BsFillPersonFill } from '@react-icons/all-files/bs/BsFillPersonFill'

const pathPrefix = {
    "tr-TR": "",
    "en-EU": "en"
}
const pathName = {
    "tr-TR": "blog",
    "en-EU": "blog"
}


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 0 25%',
        marginBottom: theme.spacing(5),
        flexDirection:'column',
    

    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 0,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            paddingLeft: 0
        }
    },
    content: {
        flex: '1 0 25%',
    },
    imageWrapper: {
        width:'100%',
        [theme.breakpoints.down('sm')]:{
            width: '100%',
            
        }
    },
    cover: {
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]:{
            width: '100%',
            height:'auto'
        }

    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    textLeftMargin: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    promoHeader: theme.typography.promoHeader,
    headerWrapper:{
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    listWrapper: {
        display: 'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        gap:'2%',
        [theme.breakpoints.down('sm')]:{
            flexDirection:'column',
        }
    }

}));

const CustomCard = props => {
    const classes = useStyles()
    return (
        <Card className={classes.root} square>
            <CardMedia className={classes.imageWrapper}>
                <Img fluid={props.fluid} className={classes.cover} />
            </CardMedia>
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography component="h5" variant="h5">
                        {props.title}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" >
                        {props.subTitle}
                    </Typography>
                    <div >
                        <FaCalendarDay />
                        <Typography variant="subtitle2" color="textSecondary" component='span' className={classes.textLeftMargin}>
                            {new Intl.DateTimeFormat(props.locale).format(new Date(props.publishDate))}
                        </Typography>
                        <BsFillPersonFill />
                        {props.author && <Typography variant="subtitle2" color="textSecondary" component='span' className={classes.textLeftMargin}>
                            {props.author}
                        </Typography>}
                    </div>
                </CardContent>
                <div className={classes.controls}>
                    <Button variant='outlined' startIcon={<FaReadme />} component={Link} to={props.link}>
                        Devamı
                    </Button>
                </div>
            </div>

        </Card>
    )
}

const Header = ({ title }) => {
    const classes = useStyles()
    return (
        <Typography component='h1' className={classes.promoHeader} align='center'>{title}</Typography>
    )
}

const BlogList = props => {
    const classes = useStyles()
    const { location } = props
    const locale = 'tr-TR'
    const data = useStaticQuery(
        graphql`
        query BlogList {
            data: allContentfulBlogYayini {
              posts: nodes {
                id
                slug
                title
                subTitle
                node_locale
                publishDate
                author
                heroImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth:300, quality:100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
    `)
    const posts = data.data.posts.filter(item => item.node_locale === locale).sort((a,b) => {return new Date(b.publishDate) - new Date(a.publishDate) })
    
    return (
        <Layout location={location} nodeLocale={locale}>
            <SEO title='Prochoice Blog Yazıları' lang={locale} location={location} description='Kedi ve Köpekler hakkında güncel faydalı bilgiler'/>
            <Grid>
                <div className={classes.headerWrapper}>
                    <Header title='Prochoice Blog' />
                </div>

                <Box className={classes.listWrapper}>
                    {posts.map(post => {
                        const path = `${pathPrefix[locale]}/${pathName[locale]}/${post.slug}/`
                        return (
                            <CustomCard key={post.id}
                                title={post.title}
                                subTitle={post.subTitle}
                                fluid={post.heroImage.localFile.childImageSharp.fluid}
                                publishDate={post.publishDate}
                                locale={locale}
                                link={path}
                                author={post.author}
                            />



                        )
                    })}

                </Box>
            </Grid>
        </Layout>
    )

}

export default BlogList